import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout.jsx'
import { PostCard } from '../components/post-card.jsx'
import { SEO } from '../components/seo.jsx'
import { Map } from '../components/post-map.jsx'

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.markdownRemark
    let wantmap = false
    let trackObjs = []
    let locationObjs = []
    if (post.frontmatter.tracks) {
      wantmap = true
      trackObjs = post.frontmatter.tracks.map(x => ({
        trackUrl: x && x.publicURL,
        title: post.frontmatter.title,
        link: post.fields.slug
      }))
    }
    if (post.frontmatter.locations) {
      wantmap = true
      locationObjs = post.frontmatter.locations
    }

    return (
      <Layout location={this.props.location} title={this.props.data.site.siteMetadata.title}>
        <article className='blogPostPage h-entry'>
          <SEO title={post.frontmatter.title} description={post.excerpt} />
          <PostCard node={post} />

          <section className='content' dangerouslySetInnerHTML={{ __html: post.html }} />

          {wantmap && <section className='geo'><Map tracks={trackObjs} locations={locationObjs} /></section>}

          <footer>
            <section className='meta tags' />
          </footer>
        </article>

        <aside className='read-next'>
          <div>
            <article className='post-card'>
              <PostCard node={this.props.data.previous} className='related-prev' rel='prev' />
            </article>
          </div>
          <div>
            <article className='post-card'>
              <PostCard node={this.props.data.next} className='related-next' rel='next' />
            </article>
          </div>
        </aside>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $previousSlug: String, $nextSlug: String) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        # See https://www.gatsbyjs.org/docs/graphql-reference/#dates
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        title
        tags
        draft
        tracks { publicURL }
        locations { gps name url}
        coverImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
    previous: markdownRemark(fields: { slug: { eq: $previousSlug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        title
        tags
        draft
        tracks { publicURL }
        coverImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
    next: markdownRemark(fields: { slug: { eq: $nextSlug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        title
        tags
        draft
        tracks { publicURL }
        coverImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }
  }
`
